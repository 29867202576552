.carousel-indicators [data-bs-target] {
    background-color: #000 !important;
}

.product-features-bg-color {
    background: linear-gradient( to top, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 40%, rgb(170, 223, 231) 40%, rgb(170, 223, 231) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-control-prev,
.carousel-control-next {
    width: auto;
}