.accordion-button {
    font-size: 23px !important;
    font-weight: bold;
}

.accordion-body {
    font-size: 18px !important;
    text-align: justify;
}

.min-card-height {
    min-height: 405px;
}

.header-title {
    font-weight: bold;
    color: #0097A7;
}

.p-learning-strategies {
    /* font-weight: bold; */
    font-size: 16px;
    margin-top: 5%;
    /* font-family: "Jost", sans-serif; */
}

.learning-strategies-bg-color {
    background: linear-gradient( to top, rgb(255, 226, 183) 0%, rgb(255, 226, 183) 30%, rgb(255, 255, 255) 30%, rgb(255, 255, 255) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}